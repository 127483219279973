<template>
  <div class="app-context expanded">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <join-progress :progressInfo="progressInfo"></join-progress>
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join document">
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">이름</h5>
          </div>
          <div class="input-wrap">
            <span class="" v-text="form.name.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">생년월일</h5>
          </div>
          <div class="input-wrap">
            <span class="" v-text="form.birth.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">성별</h5>
          </div>
          <div class="radio-input-wrap row">
            <div class="item male">
              <input
                type="radio"
                id="male"
                v-model="form.gender.value"
                value="1"
                :disabled="form.gender.value !== ''"
              />
              <label for="male">남성</label>
            </div>
            <div class="item female">
              <input
                type="radio"
                id="female"
                v-model="form.gender.value"
                value="0"
                :disabled="form.gender.value !== ''"
              />
              <label for="female">여성</label>
            </div>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">아이디</h5>
          </div>
          <div class="input-wrap onInput in-notice">
            <input
              type="text"
              placeholder="영문으로 시작하는 5~20자리 영문 또는 숫자를 입력해주세요."
              v-model="form.id.value"
              @keyup="checkIdLogic()"
              @blur="checkIdLogicActions()"
              @keyup.enter="focusControl()"
            />
            <p class="notice">
              [개인 페이지의 도메인이 됩니다. www.DrRefer.net/dr/아이디]
            </p>
            <p class="warn-message" v-if="form.id.hasError">
              {{ form.id.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">비밀번호 입력</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="password"
              placeholder="비밀번호는 영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요."
              v-model="form.password.value"
              @keyup="checkPw()"
              @blur="checkPw()"
              @keyup.enter="focusControl()"
            />
            <p class="warn-message" v-if="form.password.hasError">
              {{ form.password.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">비밀번호 확인</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="password"
              placeholder="비밀번호가 일치하지 않습니다. 다시 입력해주세요."
              v-model="form.passwordConfirm.value"
              @keyup="checkPwConfirm()"
              @blur="checkPwConfirm()"
              @keyup.enter="focusControl()"
            />
            <p class="warn-message" v-if="form.passwordConfirm.hasError">
              {{ form.passwordConfirm.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">휴대폰 번호</h5>
          </div>
          <div class="input-wrap">
            <span class="" v-text="form.phone.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">이메일</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="text"
              placeholder="정확한 형식의 이메일을 입력해주세요."
              v-model="form.email.value"
              @keyup="checkEmail()"
              @blur="checkEmail()"
              @keyup.enter="focusControl()"
            />
            <p class="warn-message" v-if="form.email.hasError">
              {{ form.email.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">병원명</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="text"
              placeholder="병원명을 입력해주세요."
              v-model="form.hospital.value"
              @keyup="checkHospital()"
              @blur="checkHospital()"
              @keyup.enter="focusControl()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.hospital.hasError">
              {{ form.hospital.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">병원전화번호</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="병원전화번호를 '-'를 포함하여 입력해주세요."
              v-model="form.hospitalTelNo.value"
              @keyup="checkHospitalTelNo()"
              @blur="checkHospitalTelNo()"
              @keyup.enter="focusControl()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.hospitalTelNo.hasError">
              {{ form.hospitalTelNo.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">근무형태</h5>
          </div>
          <div class="input-complex">
            <div class="select-place">
              <div class="select-wrap">
                <v-select
                  :items="form.employType.options"
                  :searchable="false"
                  color="reform_primary"
                  hide-details
                  flat
                  solo
                  outlined
                  dense
                  class="rounded-0"
                  item-color="reform_primary"
                  v-model="form.employType.value"
                  ref="employType"
                  @input="isEmployType()"
                  placeholder="근무형태를 선택해주세요"
                ></v-select>
                <p class="warn-message" v-if="form.employType.hasError">
                  {{ form.employType.errorTxt }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">고용형태</h5>
          </div>
          <div class="radio-input-wrap empType row">
            <div class="item">
              <input
                type="radio"
                id="delegate01"
                v-model="form.delegate.value"
                value="0"
                @click="checkDelegate(0)"
              />
              <label for="delegate01">대표의사</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="delegate02"
                v-model="form.delegate.value"
                value="1"
                @click="checkDelegate(1)"
              />
              <label for="delegate02">근무의사</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="delegate03"
                v-model="form.delegate.value"
                value="2"
                @click="checkDelegate(2)"
              />
              <label for="delegate03">기타</label>
            </div>
            <p class="warn-message" v-if="form.delegate.hasError">
              {{ form.delegate.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article" v-if="form.delegate.value === '2'">
          <div class="title-block">
            <h5 class="title">고용형태 상세</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="고용형태 상세내용을 입력해주세요."
              v-model="form.delegateEtc.value"
              @keyup="checkDelegateEtc()"
              @blur="checkDelegateEtc()"
              @keyup.enter="focusControl()"
            />
            <p class="warn-message" v-if="form.delegateEtc.hasError">
              {{ form.delegateEtc.errorTxt }}
            </p>
          </div>
        </div>
        <!--<div class="j-article" v-if="form.delegate.value === '0'">
          <div class="title-block">
            <h5 class="title">사업자 번호</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="text"
              placeholder="사업자 번호를 입력해주세요."
              v-model="form.buisnessLicense.value"
              @keyup="checkBuisnessLicense()"
              @blur="checkBuisnessLicense()"
              @keyup.enter="focusControl()"
            />
            <p class="warn-message" v-if="form.buisnessLicense.hasError">
              {{ form.buisnessLicense.errorTxt }}
            </p>
          </div>
        </div>
        <div
          class="j-article buisnessLicense"
          v-if="form.delegate.value === '0'"
        >
          <div class="title-block sein">
            <h5 class="title">사업자등록</h5>
          </div>
          <div class="attachment-wrap">
            <h5 class="title">파일명 :</h5>
            <div class="attachment row">
              <div class="a-input-wrap">
                <span
                  class="file-name"
                  v-text="form.attachment[1].filename"
                ></span>
                <input
                  class="hidden file-upload"
                  type="file"
                  @change="onFileChange(1, $event)"
                />
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="attachmentFile(1)">
                  파일첨부
                </button>
              </div>
              <p
                class="warn-message need"
                v-html="computedLine(form.attachment[1].warn)"
                v-if="form.attachment[1].filename === ''"
              ></p>
            </div>
          </div>
        </div>-->
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">의사면허번호</h5>
          </div>
          <div class="input-wrap onInput drNum">
            <input
              type="text"
              placeholder="의사면허번호를 입력해주세요."
              v-model="form.doctorLicense.value"
              @keyup="checkDoctorLicense()"
              @blur="checkDoctorLicense()"
              @keyup.enter="focusControl()"
            />
            <div class="sub-btn-wrap small" style="min-width: 100px">
              <button
                type="button"
                :class="{ complete: form.doctorLicense.done }"
                @click="checkDrLicenseActions()"
                :disabled="form.doctorLicense.done"
              >
                중복확인
              </button>
            </div>
            <p class="warn-message" v-if="form.doctorLicense.hasError">
              {{ form.doctorLicense.errorTxt }}
            </p>
            <!--<span class="viewer" v-text="form.doctorLicense"></span>-->
          </div>
        </div>
        <div class="j-article drLicense">
          <div class="title-block sein">
            <h5 class="title">의사면허등록</h5>
          </div>
          <div class="attachment-wrap">
            <h5 class="title">파일명 :</h5>
            <div class="attachment row">
              <div class="a-input-wrap">
                <span
                  class="file-name"
                  v-text="form.attachment[0].filename"
                ></span>
                <input
                  class="hidden file-upload"
                  type="file"
                  @change="onFileChange(0, $event)"
                />
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="attachmentFile(0)">
                  파일첨부
                </button>
              </div>
              <p
                class="warn-message need"
                v-html="computedLine(form.attachment[0].warn)"
                v-if="form.attachment[0].filename === ''"
              ></p>
            </div>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">병원주소</h5>
          </div>
          <div class="input-action row">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="병원주소를 입력해주세요"
                v-model="form.address.buildingname.value"
                readonly
              />
            </div>
            <div class="sub-btn-wrap small">
              <button type="button" @click="searchAddress()">주소검색</button>
            </div>
            <p class="warn-message" v-if="form.address.buildingname.hasError">
              {{ form.address.buildingname.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article last">
          <div class="title-block">
            <h5 class="title">상세주소</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="text"
              placeholder="상세주소를 입력해주세요"
              v-model="form.zipCodeDetail.value"
              @keyup="checkZipCodeDetail()"
              @blur="checkZipCodeDetail()"
              @keyup.enter="focusControl()"
            />
          </div>
        </div>
        <div class="j-article last">
          <div class="title-block">
            <h5 class="title">추천인 아이디</h5>
          </div>
          <div class="input-wrap onInput">
            <input
              type="text"
              placeholder="추천인 아이디를 입력해주세요"
              v-model="form.chuId"
            />
          </div>
        </div>
        <div class="document-btn-wrap" v-if="form.delegate.value === '0'">
          <div class="item iconIn prev" @click="goPrev()">
            <img src="@/assets/images/menuIcon/prev_mini.svg" />
            <p>이전단계</p>
          </div>
          <div
            class="item iconIn"
            @click="next()"
            :class="{
              active:
                form.name.flag &&
                form.birth.flag &&
                form.gender.flag &&
                form.id.flag &&
                form.password.flag &&
                form.passwordConfirm.flag &&
                form.phone.flag &&
                form.email.flag &&
                form.hospital.flag &&
                form.employType.flag &&
                /*form.delegate.flag &&*/
                form.doctorLicense.flag &&
                /*form.buisnessLicense.flag &&*/
                form.address.buildingname.flag /*&&
                form.attachment[0].flag &&
                form.attachment[1].flag*/,
            }"
          >
            <img src="@/assets/images/menuIcon/save.svg" />
            <p>정보저장</p>
          </div>
        </div>
        <div
          class="document-btn-wrap"
          v-if="form.delegate.value !== '0' && form.delegate.value !== '2'"
        >
          <div class="item iconIn prev" @click="goPrev()">
            <img src="@/assets/images/menuIcon/prev_mini.svg" />
            <p>이전단계</p>
          </div>
          <div
            class="item iconIn"
            @click="next()"
            :class="{
              active:
                form.name.flag &&
                form.birth.flag &&
                form.gender.flag &&
                form.id.flag &&
                form.password.flag &&
                form.passwordConfirm.flag &&
                form.phone.flag &&
                form.email.flag &&
                form.hospital.flag &&
                form.employType.flag &&
                /*form.delegate.flag &&*/
                form.doctorLicense.flag &&
                form.address.buildingname.flag /*&&
                form.attachment[0].flag*/,
            }"
          >
            <img src="@/assets/images/menuIcon/save.svg" />
            <p>정보저장</p>
          </div>
        </div>
        <div class="document-btn-wrap" v-if="form.delegate.value === '2'">
          <div class="item iconIn prev" @click="goPrev()">
            <img src="@/assets/images/menuIcon/prev_mini.svg" />
            <p>이전단계</p>
          </div>
          <div
            class="item iconIn"
            @click="next()"
            :class="{
              active:
                form.name.flag &&
                form.birth.flag &&
                form.gender.flag &&
                form.id.flag &&
                form.password.flag &&
                form.passwordConfirm.flag &&
                form.phone.flag &&
                form.email.flag &&
                form.hospital.flag &&
                form.employType.flag &&
                /*form.delegate.flag &&*/
                form.doctorLicense.flag &&
                form.delegateEtc.flag &&
                form.address.buildingname.flag /*&&
                form.attachment[0].flag*/,
            }"
          >
            <img src="@/assets/images/menuIcon/save.svg" />
            <p>정보저장</p>
          </div>
        </div>
      </div>
      <button id="focusControl"></button>
    </div>
    <!-- <app-footer></app-footer> -->
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <address-popup
      @responseAddress="getAddress($event)"
      v-if="checkAddressPopup"
      :popupSetAddress="popupSetAddress"
    />
  </div>
</template>

<script>
// import AppHeader from "@/components/AppHeader";
// import AppFooter from "@/components/AppFooter";
import JoinProgress from "@/components/join/Progress";
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AddressPopup from "@/components/modal/AddressPopup";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    // AppHeader,
    // AppFooter,
    JoinProgress,
    Directive,
    DefaultPopup,
    AddressPopup,
  },
  data() {
    return {
      progressInfo: {
        activeNum: 1,
      },
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "회원정보 입력",
        isTitleNewLine: false,
        content:
          "'기본정보'는 가입 후 진료의뢰서 발송에 필수적인 내용입니다.\n기본정보 입력 후 가입 완료됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        doctorLicense: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "의사면허번호를 입력해주세요.",
          done: true,
        },
        id: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "아이디를 입력해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "이름을 입력해주세요.",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "숫자만 입력해주세요.",
        },
        gender: {
          value: null,
          hanValue: "",
          flag: false,
          hasError: false,
          errorTxt: "성별을 선택해주세요.",
        },
        password: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "비밀번호를 입력해주세요.",
        },
        passwordConfirm: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "비밀번호입력을 확인해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "숫자만을 입력해주세요",
        },
        email: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "이메일 형식을 확인해주세요",
        },
        employType: {
          options: ["의원", "병원", "종합병원", "상급종합병원"],
          value: "" || "항목 선택",
          flag: false,
          hasError: false,
          errorTxt: "근무형태를 선택해주세요",
        },
        delegate: {
          value: null,
          flag: false,
          hasError: false,
          errorTxt: "고용형태를 선택해주세요.",
        },
        delegateEtc: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "기타내용을 입력해주세요",
        },
        buisnessLicense: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "사업자번호를 입력해주세요",
        },
        hospital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "병원명을 입력해주세요",
        },
        hospitalTelNo: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원전화번호를 입력해주세요",
        },
        zipCodeDetail: {
          value: "",
          flag: false,
        },
        address: {
          buildingname: {
            value: "",
            flag: false,
            hasError: false,
            errorTxt: "주소를 입력해주세요",
          },
          zonecode: {
            value: "",
          },
          bname: {
            value: "",
          },
          aim: {
            value: "",
          },
        },
        attachment: [
          {
            isDeleted: null,
            flag: false,
            title: "의사면허 첨부파일",
            source: null,
            url: "",
            filename: "",
            warn: "의사면허증 사본을 등록하지 않을시, 가입후 1주일 이내에 등록해 주시길 바랍니다.",
          },
          {
            isDeleted: null,
            flag: false,
            title: "사업자번호 첨부파일",
            source: null,
            url: "",
            filename: "",
            warn: "사업자등록증 사본을 등록하지 않을시, 가입후 1주일 이내에 등록해 주시길 바랍니다.",
          },
        ],
        chuId: "",
      },
      popupSet: {},
      popupSetAddress: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
    } else {
      //console.log("a");
    }
    //console.log("########################################");
    //console.log(location.href);
    //console.log("########################################");
    // 컨포넌트 생성시
    this.loadIdentificatoinData();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup", "checkAddressPopup", "isChildLoading"]),
    ...mapGetters("join", [
      "GET_PASSED_DRLICENSE",
      "GET_IDENTIFY",
      "GET_CHECKED_RESULT",
      "GET_AGREEMENT_VALUSE",
      "GET_IS_DUPLICATED_DRLICENSE",
    ]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
    genetateHyphen() {
      return (numData, isBirth) => {
        //console.log("가져온 번호들", numData);
        //console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_ADDRESS_POPUP"]),
    ...mapActions("join", [
      "SET_JOIN_BASIC",
      "CHECK_ID",
      "CHECK_DOCTOR_LICENSE",
    ]),
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    phoneFomatter(number) {
      //console.log("정보", number);
      //console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    async checkDrLicenseActions() {
      //console.log("this.form.doctorLicense : ", this.form.doctorLicense);
      if (this.form.doctorLicense.value !== "") {
        var valueBoo = /^[0-9]{1,6}$/;
        if (valueBoo.test(this.form.doctorLicense.value)) {
          if (this.form.doctorLicense.flag) {
            await this.checkDeplicatedDrLicense();
          }
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "의사면허번호 확인";
          this.popupSet.content = "*1~6자리 이하의 숫자를 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.form.doctorLicense.hasError = true;
        this.from.doctorLicense.errorTxt = "*의사면허번호를 입력해주세요.";
      }
    },
    async checkDeplicatedDrLicense() {
      try {
        await this.CHECK_DOCTOR_LICENSE(this.form.doctorLicense.value).then(
          () => {
            //console.log("서버 통신결과", this.GET_IS_DUPLICATED_DRLICENSE);

            if (!this.GET_IS_DUPLICATED_DRLICENSE) {
              /** 플래그 트루 */
              this.SET_POPUP(true);
              this.popupSet.title = "의사면허번호 확인";
              this.popupSet.content =
                "지금 입력하신 번호는 가입한 내역이 없습니다.\n회원 가입을 진행하시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              this.form.doctorLicense.flag = true;
              this.form.doctorLicense.done = true;
              this.form.doctorLicense.hasError = false;
            } else {
              /** 플래그 펄스 */
              this.SET_POPUP(true);
              this.popupSet.title = "의사면허번호 확인";
              this.popupSet.content =
                "지금 입력하신 번호는 이미 가입하신 분의 의사면허와 중복됩니다.\n가입하신 사실이 없으면 고객센터로 연락주시기 바랍니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              this.form.doctorLicense.flag = false;
              this.form.doctorLicense.hasError = true;
              this.form.doctorLicense.errorTxt =
                "*의사면허번호가 중복되었습니다.";
              this.form.doctorLicense.value = "";
            }
          }
        );
      } catch (error) {
        console.log("의사면허번호 중복검사에서 Error");
        console.log("error :", error);
      }
    },
    checkDoctorLicense() {
      this.form.doctorLicense.done = true;
      this.form.doctorLicense.flag = false;
      this.form.doctorLicense.hasError = true;
      const license = this.form.doctorLicense.value.replace(/\s/gi, "");
      if (license === "") {
        this.form.doctorLicense.flag = false;
        this.form.doctorLicense.hasError = true;
        this.form.doctorLicense.errorTxt = "*의사면허번호를 입력해주세요.";
        return false;
      }
      const isNum = /^[0-9]{1,6}$/;
      if (!isNum.test(license)) {
        this.form.doctorLicense.errorTxt =
          "*1~6자리 이하의 숫자를 입력해주세요.";
        this.form.doctorLicense.flag = false;
        this.form.doctorLicense.hasError = true;
        return false;
      }
      this.form.doctorLicense.done = false;
      this.form.doctorLicense.flag = true;
      this.form.doctorLicense.hasError = true;
      this.form.doctorLicense.errorTxt = "중복확인을 해 주세요.";
      return true;
    },
    initDelegateEtc() {
      this.form.delegateEtc.flag = false;
      this.form.delegateEtc.hasError = false;
      this.form.delegateEtc.value = "";
    },
    initBuisnessAttachment() {
      this.form.attachment[1].isDeleted = null;
      this.form.attachment[1].flag = false;
      this.form.attachment[1].source = null;
      this.form.attachment[1].url = "";
      this.form.attachment[1].filename = "";
    },
    initBuisnessLicense() {
      this.form.buisnessLicense.value = "";
      this.form.buisnessLicense.flag = false;
      this.form.buisnessLicense.hasError = false;
    },
    checkDelegateEtc() {
      this.form.delegateEtc.flag = false;
      this.form.delegateEtc.hasError = true;
      const DelefateEtc = this.form.delegateEtc.value.replace(/\s/gi, "");
      if (DelefateEtc === "") {
        this.form.delegateEtc.hasError = true;
        return false;
      }
      this.form.delegateEtc.flag = true;
      this.form.delegateEtc.hasError = false;
    },
    checkCorporateRegistrationNumber(value) {
      var valueMap = value
        .replace(/-/gi, "")
        .split("")
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (valueMap.length === 10) {
        var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
        var checkSum = 0;

        for (var i = 0; i < multiply.length; ++i) {
          checkSum += multiply[i] * valueMap[i];
        }

        checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
        return Math.floor(valueMap[9]) === 10 - (checkSum % 10);
      }

      return false;
    },
    checkBuisnessLicense() {
      this.form.buisnessLicense.flag = false;
      this.form.buisnessLicense.hasError = true;
      const BuisnessLicense = this.form.buisnessLicense.value.replace(
        /\s/gi,
        ""
      );
      if (this.form.buisnessLicense.value.length !== 10) {
        this.form.buisnessLicense.errorTxt =
          "*사업자 등록번호는 하이픈('-')을 제외한 10자리 숫자를 입력해 주세요.";
        this.form.buisnessLicense.flag = false;
        this.form.buisnessLicense.hasError = true;
      } else {
        if (BuisnessLicense === "") {
          this.form.buisnessLicense.hasError = true;
          return false;
        }
        /*if (!this.checkCorporateRegistrationNumber(BuisnessLicense)) {
        this.form.buisnessLicense.errorTxt =
          "*하이픈을 포함하여 올바른 사업자번호를 입력해주세요.";
        this.form.buisnessLicense.flag = false;
        this.form.buisnessLicense.hasError = true;
        return false;
      }*/

        this.form.buisnessLicense.flag = true;
        this.form.buisnessLicense.hasError = false;
      }
    },
    onFileChange(order, event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      //console.log("가져올 파일명: ", getFile[0].name);
      //console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment[order].source = event.target.files[0];
      /** getFile */
      this.form.attachment[order].url = getFileUrl;
      this.form.attachment[order].filename = getFile[0].name;
      this.form.attachment[order].flag = true;
      this.form.attachment[order].isDeleted = 0;
      //console.log("flag 여부", this.form.attachment[order].flag);
      //console.log("파일", event.target.files[0]);
      //console.log("소스", this.form.attachment[order].source);
    },
    attachmentFile(order) {
      let parentNode;
      if (order === 0) {
        parentNode = document.querySelector(".drLicense");
        parentNode.querySelector("input.file-upload").click();
      } else if (order === 1) {
        parentNode = document.querySelector(".buisnessLicense");
        parentNode.querySelector("input.file-upload").click();
      }
    },
    isEmployType() {
      this.form.employType.flag = true;
      this.form.employType.hasError = false;
    },
    /** 본인인증 후 넘어온 데이터 등록하기 */
    loadIdentificatoinData() {
      /** 동의정보 받기 */
      if (this.GET_IDENTIFY.birthdate !== undefined) {
        this.form.birth.value = this.GET_IDENTIFY.birthdate;
        this.form.gender.value = this.GET_IDENTIFY.gender;
        this.form.name.value = this.GET_IDENTIFY.name;
        this.form.phone.value = this.phoneFomatter(this.GET_IDENTIFY.mobileNo);
        this.form.birth.flag = true;
        this.form.gender.flag = true;
        this.form.name.flag = true;
        this.form.phone.flag = true;
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "상세내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/v/join/agreement";
      }
      if (this.GET_AGREEMENT_VALUSE.emailRcvAgrYn !== undefined) {
        //console.log("Agreement", this.GET_AGREEMENT_VALUSE);
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "상세내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/v/join/agreement";
      }
    },
    goPrev() {
      //this.$router.push("/v/join/agreement");
      window.history.go(-3);
    },
    getAddress(address) {
      //console.log("addr", address);
      //console.log("우편주소", address.zonecode);
      //console.log("주소1", address.buildingName);
      //console.log("주소2", address.bname);
      this.form.address.buildingname.flag = true;
      this.form.address.buildingname.hasError = false;
      this.form.address.buildingname.value =
        address.address + " " + address.buildingName;
      this.form.address.zonecode.value = address.zonecode;
      this.form.address.bname.value = address.bname;
      this.form.address.aim.value = address.sido + " " + address.sigungu;
      this.SET_ADDRESS_POPUP(false);
      if (document.querySelector("body").classList.contains("spreaded")) {
        document.querySelector("body").classList.remove("spreaded");
      }
    },
    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSetAddress.popType = "address";
    },
    async checkIdLogic() {
      await this.checkId();
    },
    async checkIdLogicActions() {
      await this.checkId();
      if (this.form.id.flag) {
        await this.checkDeplicatedId();
      }
    },
    async checkDeplicatedId() {
      try {
        const userData = {
          drId: this.form.id.value,
        };
        await this.CHECK_ID(userData.drId).then(() => {
          if (this.GET_CHECKED_RESULT) {
            (this.form.id.value = ""),
              (this.form.id.flag = false),
              (this.form.id.hasError = true),
              (this.form.id.errorTxt = "이미 사용중인 아이디 입니다.");
          }
        });
      } catch (error) {
        console.log("Error: ", error);
        (this.form.id.value = ""),
          (this.form.id.flag = false),
          (this.form.id.hasError = true),
          (this.form.id.errorTxt = "서버 오류로 중복체크를 할 수 없습니다.");
        /*
        if (error.data.message === "Mail already exist") {
          this.form.userEmail.flag = false;
          this.form.userEmail.hasError = true;
          this.form.userEmail.errorTxt = "이미 사용중인 이메일 입니다.";
        }
        */
      }
    },
    checkId() {
      if (this.form.id.value !== null) {
        this.form.id.flag = false;
        this.form.id.hasError = true;
        const id = this.form.id.value.replace(/\s/gi, "");
        if (id === "") {
          this.form.id.flag = false;
          this.form.id.errorTxt = "아이디를 입력해주세요.";
          return false;
        }
        const isId = /^[a-zA-Z][a-zA-Z0-9]{4,24}$/;
        if (!isId.test(id)) {
          this.form.id.errorTxt = "한글 및 영문만 입력해주세요.";
          this.form.id.flag = false;
          return false;
        }
        if (
          this.form.id.value.toUpperCase().includes("ADMIN") ||
          this.form.id.value.toUpperCase().includes("DRREFER")
        ) {
          this.form.id.errorTxt = "사용할 수 없는 아이디 입니다.";
          this.form.id.flag = false;
          //console.log("fuckj");
          return false;
        }
        this.form.id.flag = true;
        this.form.id.hasError = false;
        this.form.id.errorTxt = "";
        return true;
      }
    },
    checkName() {
      if (this.form.name.value !== null) {
        this.form.name.flag = false;
        this.form.name.hasError = true;
        const name = this.form.name.value.replace(/\s/gi, "");
        if (name === "") {
          this.form.name.flag = false;
          this.form.name.errorTxt = "이름을 입력해주세요.";
          return false;
        }
        const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,21}$/;
        if (!isHan.test(name)) {
          this.form.name.errorTxt = "한글 및 영문만 입력할 수 있습니다.";
          this.form.name.flag = false;
          return false;
        }
        this.form.name.flag = true;
        this.form.name.hasError = false;
        this.form.name.errorTxt = "";
        return true;
      }
    },
    checkPw() {
      if (this.form.password.value !== null) {
        this.form.password.flag = false;
        this.form.password.hasError = true;
        const pw = this.form.password.value.replace(/\s/gi, "");
        if (pw === "") {
          this.form.password.flag = false;
          this.form.password.hasError = true;
          this.form.password.errorTxt = "비밀번호를 입력해주세요.";
          return false;
        }
        const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
        if (!isPw.test(pw)) {
          this.form.password.errorTxt =
            "영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.";
          this.form.password.flag = false;
          this.form.password.hasError = true;
          return false;
        }
        this.form.password.flag = true;
        this.form.password.hasError = false;
        this.form.password.errorTxt = "";
        return true;
      }
    },
    checkPwConfirm() {
      if (this.form.passwordConfirm.value !== null) {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        const pwConfirm = this.form.passwordConfirm.value.replace(/\s/gi, "");
        if (pwConfirm === "") {
          this.form.passwordConfirm.flag = false;
          this.form.passwordConfirm.hasError = true;
          this.form.passwordConfirm.errorTxt = "비밀번호 확인을 입력해주세요.";
          return false;
        }
        if (pwConfirm !== this.form.passwordConfirm.value) {
          this.form.passwordConfirm.flag = false;
          this.form.passwordConfirm.hasError = true;
          this.form.passwordConfirm.errorTxt = "비밀번호가 일치하지 않습니다.";
          return false;
        }
        this.form.passwordConfirm.flag = true;
        this.form.passwordConfirm.hasError = false;
        this.form.passwordConfirm.errorTxt = "";
        return true;
      }
    },
    checkBirth() {
      if (this.form.birth.value !== null) {
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        const birth = this.form.birth.value.replace(/\s/gi, "");
        if (birth === "") {
          this.form.birth.flag = false;
          this.form.birth.hasError = true;
          this.form.birth.errorTxt = "생년월일을 입력해주세요.";
          return false;
        }
        const isBirth =
          /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (!isBirth.test(birth)) {
          this.form.birth.errorTxt = "YYYY-MM-DD 형식에 맞춰서 입력해주세요.";
          this.form.birth.flag = false;
          this.form.birth.hasError = true;
          return false;
        }
        this.form.birth.flag = true;
        this.form.birth.hasError = false;
        this.form.birth.errorTxt = "";
        return true;
      }
    },
    checkgender(data) {
      if (data === "1") {
        this.form.gender.hanValue = "남성";
      } else {
        this.form.gender.hanValue = "여성";
      }
      this.form.gender.flag = true;
      this.form.gender.hasError = false;
    },
    checkDelegate(order) {
      this.initDelegateEtc();
      this.initBuisnessLicense();
      this.initBuisnessAttachment();
      this.form.delegate.flag = true;
      this.form.delegate.hasError = false;
      if (order === 0) {
        this.form.buisnessLicense.hasError = true;
      } else if (order === 2) {
        this.form.delegateEtc.hasError = true;
      }
    },
    checkPhone() {
      if (this.form.phone.value !== null) {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        const phone = this.form.phone.value.replace(/\s/gi, "");
        if (phone === "") {
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          this.form.phone.errorTxt = "핸드폰번호를 입력해주세요.";
          return false;
        }
        const isPhone = /^[0-9]{8,11}$/;
        if (!isPhone.test(phone)) {
          this.form.phone.errorTxt = "숫자만을 입력해주세요.";
          this.form.phone.flag = false;
          this.form.phone.hasError = true;
          return false;
        }
        this.form.phone.flag = true;
        this.form.phone.hasError = false;
        this.form.phone.errorTxt = "";
        return true;
      }
    },
    checkEmail() {
      if (this.form.email.value !== null) {
        this.form.email.flag = false;
        this.form.email.hasError = true;
        const email = this.form.email.value.replace(/\s/gi, "");
        if (email === "") {
          this.form.email.flag = false;
          this.form.email.hasError = true;
          this.form.email.errorTxt = "이메일을 입력해주세요.";
          return false;
        }

        const isEmail =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!isEmail.test(email)) {
          this.form.email.flag = false;
          this.form.email.hasError = true;
          this.form.email.errorTxt = "이메일 형식에 맞지 않습니다.";
          return false;
        }
        this.form.email.flag = true;
        this.form.email.hasError = false;
        this.form.email.errorTxt = "";
        return true;
      }
    },
    checkHospital() {
      if (this.form.hospital.value !== null) {
        this.form.hospital.flag = false;
        this.form.hospital.hasError = true;
        const hospital = this.form.hospital.value.replace(/\s/gi, "");
        if (hospital === "") {
          this.form.hospital.flag = false;
          this.form.hospital.errorTxt = "병원명을 입력해주세요.";
          return false;
        }
        const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9]{2,40}$/;
        if (!isHan.test(hospital)) {
          this.form.hospital.errorTxt = "한글,영문,숫자만 입력할 수 있습니다.";
          this.form.hospital.flag = false;
          return false;
        }
        this.form.hospital.flag = true;
        this.form.hospital.hasError = false;
        this.form.hospital.errorTxt = "";
        return true;
      }
    },
    checkHospitalTelNo() {
      this.form.hospitalTelNo.flag = false;
      this.form.hospitalTelNo.hasError = true;
      const hospitalTelNo = this.form.hospitalTelNo.value.replace(/\s/gi, "");
      if (hospitalTelNo === "") {
        this.form.hospitalTelNo.flag = false;
        this.form.hospitalTelNo.errorTxt =
          "*병원 전화번호를 '-'를 포함하여 입력해주세요.";
        return false;
      }
      const isHan = /^\d{2,3}-\d{3,4}-\d{4}$/;
      if (!isHan.test(hospitalTelNo)) {
        this.form.hospitalTelNo.errorTxt =
          "*지역 전화번호 형식에 맞게 입력할 수 있습니다.";
        this.form.hospitalTelNo.flag = false;
        return false;
      }
      this.form.hospitalTelNo.flag = true;
      this.form.hospitalTelNo.hasError = false;
      this.form.hospitalTelNo.errorTxt = "";
      return true;
    },
    initFlag() {},
    checkFlag() {
      if (
        !this.form.name.flag ||
        !this.form.birth.flag ||
        !this.form.gender.flag ||
        !this.form.id.flag ||
        !this.form.password.flag ||
        !this.form.passwordConfirm.flag ||
        !this.form.phone.flag ||
        !this.form.email.flag ||
        !this.form.hospital.flag ||
        !this.form.hospitalTelNo.flag ||
        !this.form.employType.flag /*||
        !this.form.delegate.flag*/ ||
        !this.form.address.buildingname.flag ||
        !this.form.doctorLicense.flag /*||
        !this.form.attachment[0].flag*/
      ) {
        //console.log("체크 기본걸림");
        //console.log(this.form.name.flag);
        //console.log(this.form.birth.flag);
        //console.log(this.form.gender.flag);
        //console.log(this.form.id.flag);
        //console.log(this.form.password.flag);
        //console.log(this.form.passwordConfirm.flag);
        //console.log(this.form.phone.flag);
        //console.log(this.form.email.flag);
        //console.log(this.form.hospital.flag);
        //console.log(this.form.hospitalTelNo.flag);
        //console.log(this.form.employType.flag);
        //console.log(this.form.address.buildingname.flag);
        //console.log(this.form.doctorLicense.flag);
        //console.log("--");
        //console.log(this.form.delegate.flag);
        //console.log(this.form.buisnessLicense.flag);

        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.name.flag) {
          this.form.name.hasError = true;
        }
        if (!this.form.birth.flag) {
          this.form.birth.hasError = true;
        }
        if (!this.form.gender.flag) {
          this.form.gender.hasError = true;
        }
        if (!this.form.id.flag) {
          this.form.id.hasError = true;
        }
        if (!this.form.password.flag) {
          this.form.password.hasError = true;
        }
        if (!this.form.passwordConfirm.flag) {
          this.form.passwordConfirm.hasError = true;
        }
        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
        if (!this.form.email.flag) {
          this.form.email.hasError = true;
        }
        if (!this.form.hospital.flag) {
          this.form.hospital.hasError = true;
        }
        if (!this.form.hospitalTelNo.flag) {
          this.form.hospitalTelNo.hasError = true;
        }
        if (!this.form.employType.flag) {
          this.form.employType.hasError = true;
        }
        /*if (!this.form.delegate.flag) {
          this.form.delegate.hasError = true;
        }*/
        /*if (!this.form.attachment[0].flag) {
          this.form.attachment[0].hasError = true;
        }*/
        if (!this.form.doctorLicense.flag) {
          this.form.doctorLicense.hasError = true;
        }
        if (!this.form.address.buildingname.flag) {
          this.form.address.buildingname.hasError = true;
        }
        return false;
      }
      /*if (this.form.delegate.value === "0") {
        if (!this.form.buisnessLicense.flag) {
          this.form.buisnessLicense.hasError = true;
          return false;
        }
        /*if (!this.form.attachment[1].flag) {
          this.form.attachment[1].hasError = true;
          return false;
        }*/
      /*} else*/ if (this.form.delegate.value === "2") {
        if (!this.form.delegateEtc.flag) {
          this.form.delegateEtc.hasError = true;
          return false;
        }
      }
      return true;
    },
    checkZipCodeDetail() {
      this.form.zipCodeDetail.flag = false;
      if (this.form.zipCodeDetail.value !== null) {
        const zipCodeDetail = this.form.zipCodeDetail.value.replace(/\s/gi, "");
        if (zipCodeDetail === "") {
          this.form.zipCodeDetail.flag = false;
          return false;
        }
        this.form.zipCodeDetail.flag = true;
        return true;
      }
    },
    async next() {
      if (this.checkFlag()) {
        const basicParam = {
          licNo: this.form.doctorLicense.value,
          drNm: this.form.name.value,
          birth: this.form.birth.value,
          sex: this.form.gender.value,
          drId: this.form.id.value,
          passwd: this.form.password.value,
          hpNo: this.form.phone.value.replace(/-/gi, ""),
          email: this.form.email.value,
          hospNm: this.form.hospital.value,
          telNo: this.form.hospitalTelNo.value,
          postNo: this.form.address.zonecode.value,
          addr: this.form.address.buildingname.value,
          addr2: this.form.address.aim.value,
          addrDtl: this.form.zipCodeDetail.value,
          workType: this.form.employType.value,
          repType: this.form.delegate.value,
          emailRcvAgrYn: this.GET_AGREEMENT_VALUSE.emailRcvAgrYn,
          smsRcvAgrYn: this.GET_AGREEMENT_VALUSE.smsRcvAgrYn,
          mktCollAgrYn: this.GET_AGREEMENT_VALUSE.mktCollAgrYn,
          chuId: this.form.chuId,
        };
        if (this.form.delegate.value === "0") {
          basicParam.saupjaNo = this.form.buisnessLicense.value.replace(
            /-/gi,
            ""
          );
        } else if (this.form.delegate.value === "2") {
          basicParam.etcRepType = this.form.delegateEtc.value;
        }
        const transData = new FormData();
        if (this.form.attachment[0].source !== null) {
          transData.append("licFile", this.form.attachment[0].source);
          basicParam.isDeletedLicAttchId = 0;
        }
        if (this.form.attachment[1].source !== null) {
          transData.append("saupjaFile", this.form.attachment[1].source);
          basicParam.isDeletedSaupjaAttchId = 0;
        }
        const transedBasicParam = JSON.stringify(basicParam);
        transData.append("basicParam", transedBasicParam);
        //console.log("서버로 전송할 베이직", basicParam);
        try {
          await this.SET_JOIN_BASIC(transData).then(() => {
            this.$router.push("/v/join/done").catch(() => {});
          });
        } catch (error) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
  },
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/Member";

.title {
  margin-right: 15px !important;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
