<template>
  <div class="progress-context">
    <div class="progress-wrap">
      <div class="progress">
        <div class="liner"></div>
        <div
          class="item"
          v-for="(progressItem, index) in progressData"
          :key="index"
          :class="{ active: currentNum === index }"
        >
          <span class="circle"></span>
          <p>{{ progressItem.progressName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progressInfo: {
      activeNum: {
        type: Number,
      },
      scrollAble: {
        type: Boolean,
      },
    },
  },

  components: {},
  data() {
    return {
      currentNum: this.progressInfo.activeNum,
      progressData: [
        {
          progressName: "약관동의",
        },
        {
          progressName: "회원정보 입력",
        },
        {
          progressName: "가입완료",
        },
      ],
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.fixed {
  .progress-wrap {
    background: transparent;
    position: relative;
  }
}
.progress-context {
  height: 194px;
  position: relative;
  padding: 35px 0 0 0;
}
.progress-wrap {
  background: #fff;
  height: inherit;
  position: relative;
  width: 100%;
  &.progressUp {
    background: #fff;
    box-shadow: none;
    transform: translate3d(0, -100%, 0);
    transition: 0.5s;
  }
}
.progress {
  display: flex;
  justify-content: space-around;
  width: 630px;
  height: auto;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background-color: #ffffff;
  .liner {
    background: #f2f2f2;
    height: 3px;
    position: absolute;
    width: calc(100% - 190px);
    transform: translateX(-50%);
    left: 50%;
    top: 8.5px;
  }
  .item {
    text-align: center;
    position: relative;
    min-width: 105px;
    &.active {
      span.circle {
        background: #146f83;
      }
    }
    span.circle {
      display: block;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background: #f2f2f2;
      margin: 0 auto 15px auto;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
    p {
      font-family: "SUIT";
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: normal;
      text-align: center;
      color: #146f83;
      padding-top: 36px;
    }
  }
}
@media screen and (max-width: 768px) {
  .progress {
    width: 500px !important;
  }
}
@media screen and (max-width: 425px) {
  .progress {
    width: 300px !important;
    .liner {
      background: #f2f2f2;
      height: 3px;
      position: absolute;
      width: calc(100% - 100px);
      transform: translateX(-50%);
      left: 50%;
      top: 8.5px;
    }
  }
}
</style>
